import React from "react";
import "./App.css";
import ClaimPolicy from "./pages/claimpolicy";

const App = () => (
	<div className="app">
		<ClaimPolicy />
	</div>
);

export default App;
