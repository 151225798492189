import React from "react";
import { Input, Layout, Col, Row, DatePicker, Button, notification } from "antd";
import "./styles.css";
import moment from "moment";
import async from "async";

import logo from "../../logo.png";
import { firestore, storage } from "../../firebase";

const { Header, Content, Footer } = Layout;

type Props = {};

type State = {
	ID: string,
	date: moment | null,
	notes: string,
	files: any,
	urls: any,
	imageURL: string,
	progress: number,
	insurance: any | null,
	refID: any,
};

class ClaimPolicy extends React.Component<Props, State> {
	state = {
		ID: "",
		date: null,
		notes: "",
		files: [],
		urls: [],
		imageURL: "",
		progress: 0,
		insurance: null,
		refID: "",
	};
	saveClaim = async () => {
		const { ID, date, notes, files } = this.state;
		if (!ID || !date || !notes || !files) {
			return notification.error({
				message: "Woops! something is missing",
				description: "All fields must be filled out",
			});
		}
		await async.eachOf(files, (file, key, callback) => {
			const uploadTask = storage
				.ref()
				.child(`claims/${ID}/${file.name}`)
				.put(file);
			uploadTask.on(
				"state_changed",
				snapshot => {
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
					this.setState({ progress });
				},
				error => {
					notification.error({ message: "Unable to upload image", description: error.message });
				},
				() => {
					return uploadTask.snapshot.ref.getDownloadURL().then(url => {
						if (url) {
							this.setState({ urls: [...this.state.urls, url] });
							return callback();
						} else {
							notification.error({
								message: "Something went wrong",
								description: url,
							});
						}
						return null;
					});
				}
			);
			return null;
		});
		await firestore
			.collection(this.state.ID.startsWith("C") ? "car-insurance" : "truck-insurance")
			.where("insuranceID", "==", this.state.ID)
			.get()
			.then(items => {
				return items.docs.map(item => this.setState({ refID: item.id }));
			});
		await firestore
			.collection("claims")
			.add({
				insuranceID: ID,
				date,
				notes,
				insurance: ID.startsWith("C") ? firestore.doc("car-insurance/" + this.state.refID) : firestore.doc("truck-insurance/" + this.state.refID),
				images: this.state.urls,
			})
			.then(() => {
				return this.setState(
					{
						ID: "",
						date: null,
						notes: "",
						files: [],
						urls: [],
						imageURL: "",
						insurance: null,
						progress: 0,
					},
					() =>
						notification.success({
							message: "Upload successful!",
							description: "A Representative will contact you.",
						})
				);
			});
		return null;
	};
	onFilesChange = (e: any) => {
		const files = [];
		Object.values(e.target.files).forEach(file => files.push(file));
		this.setState({ files });
	};

	render() {
		return (
			<Layout className="page">
				<Header style={{ backgroundColor: "#11489F" }} />
				<Content style={{ padding: "0 5vw" }}>
					<Col span={24}>
						<Row span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<img alt="Global Insurance BP" src={logo} style={{ margin: 20, maxWidth: 410 }} />
						</Row>
					</Col>
					<div className="content">
						<Row gutter={[10, 30]}>
							<Col span={24}>
								<Row span={24}>
									<h2>Insurance ID</h2>
								</Row>
								<Row span={24}>
									<Input
										style={{ height: 50 }}
										value={this.state.ID}
										onChange={e => this.setState({ ID: e.currentTarget.value.charAt(0).toUpperCase() + e.currentTarget.value.slice(1) })}
									/>
								</Row>
							</Col>
							<Col span={24}>
								<Row span={24}>
									<h2>Date of accident</h2>
								</Row>
								<Row span={24}>
									<DatePicker onChange={e => this.setState({ date: e.toDate() })} style={{ width: "100%", height: 50, fontSize: 20 }} />
								</Row>
							</Col>
							<Col span={24}>
								<Row span={24}>
									<h2>Upload photos of accident</h2>
								</Row>
								<Row span={24}>
									<input className="file-input" type="file" onChange={this.onFilesChange} multiple />
								</Row>
							</Col>
							<Col span={24}>
								<Row span={24}>
									<h2>Please briefly explain what caused the accident.</h2>
								</Row>
								<Row span={24}>
									<Input.TextArea
										rows={5}
										style={{ width: "100% !important", fontSize: 20 }}
										value={this.state.notes}
										onChange={e => this.setState({ notes: e.currentTarget.value })}
									/>
								</Row>
							</Col>
							<Col span={24}>
								<Row style={{ display: "flex" }} span={24}>
									<Button type="primary" style={{ height: 40, width: 100, fontSize: 20 }} onClick={() => this.saveClaim()}>
										Save
									</Button>
								</Row>
							</Col>
						</Row>
					</div>
				</Content>
				<Footer style={{ textAlign: "center" }}>
					Developed by:{" "}
					<a href={"https://studiomediaagency.com"} target={"_blank"}>
						Studio Media Agency
					</a>
				</Footer>
			</Layout>
		);
	}
}

export default ClaimPolicy;
